import 'core-js/stable'
import 'regenerator-runtime/runtime'
import * as $ from 'jquery'
import 'lazysizes'

import '../src/util/install_sentry'
import 'Components/site/search_widget/component'
import 'Components/site/contract_count_widget/component'
import 'Components/site/company_card/component'
import 'Components/site/latest_high_rate_reviews_widget/component'
import 'Components/site/example_card/component'
import 'Components/site/review_card/component'
import 'Components/site/guide_columns/component'
import 'Components/site/site_footer/component'
import 'Components/site/site_header/component'
import 'Components/site/site_header_navigation/component'
import 'Components/site/main_visual/component'
import 'Components/site/tab_navigation/component'
import 'Components/site/carousel_light/component'
import 'Components/site/search_by_part_type/component'
import 'Components/site/part_type_card/component'
import 'Components/site/recent_example_card/component'
import 'Components/site/chosen_points/component'
import 'Components/site/reform_flow/component'
import 'Components/site/call_to_action/component'
import 'Components/site/call_to_action_button/component'
import 'Components/site/call_to_action_link/component'
import 'Components/site/floating/component'
import 'Components/site/commercial_form/component'

import spToggleNav from '../src/spToggleNav/spToggleNav'

$(() => {
  spToggleNav()
})
