import { Application, Controller } from 'stimulus'
import './component.scss'

const app = Application.start()
const defaultOptionCityText = '市区町村'
const constantQueryParams = [
  'nuri_source=seo',
  'nuri_medium=top',
  'nuri_campaign=outer-wall',
  'nuri_content=nav',
]

const activateOpacity = 1
const inActivateOpacity = 0.5

const smallScreenWidthBorder = 768

app.register('city-fetcher', class extends Controller {
  initialize() {
    this.citySelector = document.getElementById('city_list')
    this.addDataActions()
  }

  addDataActions() {
    const prefectureForm = document.getElementById('prefecture_list')
    const resetCitiesAction = 'change->city-fetcher#resetCityList'
    const relatedCityAction = 'change->city-fetcher#addRelatedCities'

    prefectureForm.setAttribute('data-action', `${resetCitiesAction} ${relatedCityAction}`)
  }

  resetCityList() {
    this.removeCityNames()
    this.addDefaultOption()
  }

  addRelatedCities(e) {
    const selectedValue = e.target.value

    if (selectedValue === '') {
      return
    }

    const promise = fetch(`/api/prefecture/${selectedValue}/cities`)

    promise.then((response) => {
      return response.json()
    })
    .then((cities) => {
      cities.forEach(city => {
        const option = document.createElement('option')
        option.value = city.name
        option.text = city.name

        this.citySelector.appendChild(option)
      })
    })
  }

  removeCityNames() {
    while(this.citySelector.firstChild){
      this.citySelector.removeChild(this.citySelector.firstChild)
    }
  }

  addDefaultOption() {
    const option = document.createElement('option')
    option.text = defaultOptionCityText
    option.value = ''

    this.citySelector.appendChild(option)
  }
})

app.register('redirect-lp-button-activator', class extends Controller {
  static targets = ['dropdown']

  initialize() {
    this.addDataActions()
    this.addDataTargets()
  }

  addDataActions() {
    const forms = document.getElementsByClassName('cta-content__dropdown')
    const newAction = 'change->redirect-lp-button-activator#toggleActivationTransitionLpButton'

    forms.forEach(form => {
      const currentAction = form.getAttribute('data-action')

      if (currentAction) {
        form.setAttribute('data-action', `${currentAction} ${newAction}`)
      } else {
        form.setAttribute('data-action', newAction)
      }
    })
  }

  addDataTargets() {
    const forms = document.getElementsByClassName('cta-content__dropdown')
    const newTarget = 'data-redirect-lp-button-activator-target'

    forms.forEach(form => form.setAttribute(newTarget, 'dropdown'))
  }

  toggleActivationTransitionLpButton() {
    const redirectButton = document.getElementById('lp-redirect-button')

    if (this.hasInputtedAllForm()) {
      redirectButton.setAttribute('href', this.lpPath())
      redirectButton.setAttribute('target', '_blank')
      redirectButton.setAttribute('rel', 'noopener noreferrer')
      redirectButton.style.opacity = activateOpacity
    } else {
      redirectButton.removeAttribute('href')
      redirectButton.removeAttribute('target')
      redirectButton.removeAttribute('rel')
      redirectButton.style.opacity = inActivateOpacity
    }
  }

  lpPath() {
    if (window.innerWidth < smallScreenWidthBorder) {
      return `lp/exterior_outer-wall/sp/seo/0009?${this.formattedQueryParam()}`
    } else {
      return `lp/exterior_outer-wall/pc/seo/0009?${this.formattedQueryParam()}`
    }
  }

  hasInputtedAllForm() {
    return this.dropdownTargets.every(dropdown => {
      return dropdown.value != ''
    })
  }

  formattedQueryParam() {
    return constantQueryParams.concat(this.variableQueryParams()).join('&')
  }

  variableQueryParams() {
    return [
      `prefecture=${this.selectedValue('prefecture_list')}`,
      `city=${this.selectedValue('city_list')}`,
      `floor_square_meter=${this.selectedValue('floor_square_meter_list')}`,
      `paint=${this.selectedValue('paint_type_list')}`,
    ]
  }

  selectedValue(item) {
    return document.getElementById(item).value
  }
})

app.register('form-decorator', class extends Controller {
  initialize() {
    this.toggleDecoratingCtaForm(this.findUnselectedFirstForm())
    this.addDataActions()
    this.addDropdownIcon()
  }

  addDataActions() {
    const forms = document.getElementsByClassName('cta-content__dropdown')
    const newActions = 'change->form-decorator#toggleFormsStyle change->form-decorator#toggleFormIcons'

    forms.forEach(form => {
      const currentAction = form.getAttribute('data-action')

      if (currentAction) {
        form.setAttribute('data-action', `${currentAction} ${newActions}`)
      } else {
        form.setAttribute('data-action', newActions)
      }
    })
  }

  toggleFormsStyle() {
    this.resetFormStyle()
    this.decorateUnselectedFirstFormStyle()
  }

  resetFormStyle() {
    const unselectedFirstForm = document.getElementsByClassName('cta-content__dropdown--unselected')[0]
    this.toggleDecoratingCtaForm(unselectedFirstForm)
  }

  decorateUnselectedFirstFormStyle() {
    this.toggleDecoratingCtaForm(this.findUnselectedFirstForm())
  }

  findUnselectedFirstForm() {
    const ctaForms = Array.from(document.getElementsByClassName('cta-content__dropdown'))

    return ctaForms.find(form => form.value === '')
  }

  toggleDecoratingCtaForm(targetCtaForm) {
    if (targetCtaForm === undefined) {
      return
    }

    targetCtaForm.classList.toggle('cta-content__dropdown--unselected')
  }

  addDropdownIcon() {
    const forms = Array.from(document.getElementsByClassName('cta-content__form-wrapper'))

    forms.forEach(form => {
      const element = document.createElement('div')
      element.classList.add('cta-content__dropdown-icon')

      form.appendChild(element)
    })
  }

  toggleFormIcons() {
    const forms = Array.from(document.getElementsByClassName('cta-content__dropdown'))

    forms.forEach(form => {
      const dropDownIcon = form.parentNode.querySelector('.cta-content__dropdown-icon')

      if (form.value !== '' && dropDownIcon !== null) {
        dropDownIcon.remove()

        const checkIconElement = document.createElement('div')
        checkIconElement.classList.add('cta-content__check-icon')
        form.parentNode.appendChild(checkIconElement)
      } else if (form.value === '' && dropDownIcon === null) {
        const checkIcon = form.parentNode.querySelector('.cta-content__check-icon')
        checkIcon.remove()

        const dropdownIconElement = document.createElement('div')
        dropdownIconElement.classList.add('cta-content__dropdown-icon')
        form.parentNode.appendChild(dropdownIconElement)
      }
    })
  }
})
