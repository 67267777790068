import './component.scss'

import 'Components/site/helpful_widget/component'

var $ = require('jquery')
require('slick-carousel')

$(function () {
  $('.js-review-card-carousel').slick({
    speed: 800,
    dots: false,
    infinite: false,
    arrows: true,
    centerMode: true,
    centerPadding: '9%',
    lazyLoad: 'progressive',
    responsive: [{
      breakpoint: 768,
      settings: {
        centerPadding: '0',
        arrows: true
      }
    }]
  })
})
